export const TIME_IS_UP = "TIME_IS_UP";
export const USER_IS_ACTIVE = "USER_IS_ACTIVE";
export const TICK = "TICK";

export const ACTIVITY_EVENTS = [
  "mousedown",
  "mousemove",
  "keydown",
  "scroll",
  "touchstart",
];

export const MAX_DURATION_IN_SECONDS = 600; // Ten minutes
