import { useActor } from "@xstate/react";
import { useMemo } from "react";

import { ACTIVE_SESSION_MACHINE } from "../../constants";
import { logService } from "../../helpers";

import { ActiveSessionService } from "./types";

export * from "./machine";
export * from "./types";

const TIME_THRESHHOLD = 15;

export const useActiveSessionService = (
  activeSessionService: ActiveSessionService,
) => {
  const [state, send] = useActor(activeSessionService);
  logService(ACTIVE_SESSION_MACHINE, activeSessionService);
  const context = state?.context;

  const timeRemaining: number = useMemo(
    () => (context?.duration ?? 30) - (context?.elapsed || 0),
    [context],
  );

  const timeIsAlmostUp: boolean = useMemo(
    () => timeRemaining <= TIME_THRESHHOLD,
    [timeRemaining],
  );

  return [{ timeIsAlmostUp, timeRemaining }];
};
