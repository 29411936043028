import { Machine, assign } from "xstate";

import {
  TIME_IS_UP,
  USER_IS_ACTIVE,
  TICK,
  ACTIVITY_EVENTS,
  MAX_DURATION_IN_SECONDS,
} from "./constants";
import {
  ActiveSessionContext,
  ActiveSessionScheme,
  ActiveSessionEvents,
} from "./types";

export const activeSessionMachine = Machine<
  ActiveSessionContext,
  ActiveSessionScheme,
  ActiveSessionEvents
>({
  id: "activeSession",
  initial: "running",
  context: {
    elapsed: 0,
    duration: MAX_DURATION_IN_SECONDS,
  },
  states: {
    running: {
      invoke: [
        {
          id: "setListeners",
          src: (_context) => (cb) => {
            const notifyUserActive = () => cb(USER_IS_ACTIVE);
            ACTIVITY_EVENTS.forEach((eventName) => {
              document.addEventListener(eventName, notifyUserActive, true);
            });
            return () =>
              ACTIVITY_EVENTS.forEach((eventName) => {
                document.removeEventListener(eventName, notifyUserActive, true);
              });
          },
        },
        {
          id: "setTimer",
          src: (_context) => (cb) => {
            const interval = setInterval(() => {
              cb(TICK);
            }, 1000);
            return () => {
              clearInterval(interval);
            };
          },
        },
      ],
      always: {
        target: TIME_IS_UP,
        cond: (context: ActiveSessionContext) => {
          return context.elapsed >= context.duration;
        },
      },
      on: {
        [USER_IS_ACTIVE]: {
          actions: assign({
            elapsed: (_context) => 0,
          }),
        },
        [TICK]: {
          actions: assign({
            elapsed: (context) => context.elapsed + 1,
          }),
        },
      },
    },
    [TIME_IS_UP]: {
      type: "final",
    },
  },
});
