import { IconButton } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { useNetworkQuality, useVideoContext } from "../../hooks";

import { NetworkQualityIndicator } from "./network-quality-indicator";

interface ControlQualityProps {
  className?: string;
}
export const ControlQuality: FunctionComponent<ControlQualityProps> = ({
  className,
}) => {
  const { room } = useVideoContext();
  const networkQualityLevel = useNetworkQuality(room.localParticipant);

  if (networkQualityLevel === null || networkQualityLevel === undefined) {
    return null;
  }

  return (
    <IconButton disabled className={className}>
      <NetworkQualityIndicator participant={room.localParticipant} />
    </IconButton>
  );
};
