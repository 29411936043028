import { Grid, makeStyles } from "@material-ui/core";
import {
  NurseVisit,
  NurseVisitStatus,
  ProviderUser,
  Visit,
} from "@relieftelemed/platform";
import React, { FunctionComponent, useEffect, useState } from "react";

import {
  DetailDrawer,
  GroupedVisitList,
  TabbedNavigation,
} from "../../components";
import {
  GROUPINGS,
  useFilteredVisits,
  useGroupedVisits,
  usePageTitle,
} from "../../hooks";
import { usePatientContext } from "../../patients";
import { useAppService } from "../../state";
import { NurseVisitDetails } from "../details";

import { NurseVisitCard } from "./nurse-visit-card";
import { useNurseVisitsService } from "./state";

const GROUP_LABELS = {
  [GROUPINGS.OWN]: "Potential Ninjas in Disguise",
  [GROUPINGS.CLINIC]: "Current Clinic",
  [GROUPINGS.OTHERS]: "All Clinics",
};
const STATUSES = Object.values(NurseVisitStatus);

const useListingStyles = makeStyles((theme) => ({
  panel: {
    padding: `0 ${theme.spacing(2)}px`,
  },
}));

export const NurseVisitsPage: FunctionComponent = () => {
  const classes = useListingStyles();

  const setTitle = usePageTitle();
  const currentPatient = usePatientContext();
  const [{ user }] = useAppService();
  const [{ isReady, visits }, { fetch }] = useNurseVisitsService();

  const [selectedTab, changeTab] = useState<string>(STATUSES[0]);
  const [selectedVisit, changeSelected] = useState<NurseVisit | undefined>(
    undefined,
  );

  const activeClinic = user!.currentClinic?.id ?? undefined;
  const activeClinicName = user!.currentClinic?.name ?? "Current Clinic";
  const providerId = (user as ProviderUser).providerId ?? undefined;

  const filteredVisits = useFilteredVisits<NurseVisitStatus, NurseVisit>(
    STATUSES,
    visits,
    currentPatient,
  );

  const visitsToRender: NurseVisit[] =
    filteredVisits[selectedTab as NurseVisitStatus];

  const groupedVisits = useGroupedVisits<NurseVisit>(
    visitsToRender,
    activeClinic,
    providerId,
  );

  useEffect(() => {
    if (isReady && visits.length === 0) {
      fetch();
    }
  }, [fetch, isReady, visits]);

  useEffect(() => {
    const titlePrefix = `${selectedTab} `;

    const titleSuffix = currentPatient ? ` for ${currentPatient.fullName}` : "";
    setTitle(`${titlePrefix} Nurse Visits${titleSuffix}`);
  }, [setTitle, selectedTab, currentPatient]);

  const handleSelect = (visit: Visit) => () => {
    if (selectedVisit?.id === visit.id) {
      changeSelected(undefined);
      fetch();
      return;
    }
    changeSelected(visit as NurseVisit);
  };

  let details;
  if (selectedVisit) {
    details = <NurseVisitDetails visit={selectedVisit} />;
  }

  return (
    <>
      <TabbedNavigation
        onChange={changeTab}
        statusCounts={Object.keys(filteredVisits).map((status) => ({
          count: filteredVisits[status as NurseVisitStatus].length,
          status,
        }))}
      />
      <Grid container>
        <Grid item xs={12} className={classes.panel}>
          <GroupedVisitList
            groupLabels={GROUP_LABELS}
            onSelect={handleSelect}
            selected={selectedVisit}
            activeClinicName={activeClinicName}
            VisitCard={NurseVisitCard}
            groupedVisits={groupedVisits}
          />
        </Grid>
        <DetailDrawer
          open={!!selectedVisit}
          onClose={() => changeSelected(undefined)}
        >
          {details}
        </DetailDrawer>
      </Grid>
    </>
  );
};
