import {
  Button,
  CardActions,
  CardContent,
  Divider,
  Grid,
  Hidden,
  makeStyles,
} from "@material-ui/core";
import React, { FunctionComponent, ReactNode, useState, useMemo } from "react";
import { Link } from "react-router-dom";

import {
  ConfirmationModal,
  DefinedProperty,
  VisitDetailCard,
  LoadingButton,
} from "../../components";
import { emptyValue } from "../../config";
import { PATH_CONSULTATION } from "../../constants";
import { VideoVisit, VideoVisitStatus } from "../../core";
import { createRoute, renderDate } from "../../helpers";
import { reliefTeal, white } from "../../styles";

import { useVideoVisitService } from "./state";

interface VideoVisitDetailsProps {
  visit: VideoVisit;
}

const useCardStyles = makeStyles((theme) => ({
  content: {
    padding: `${theme.spacing(3)}px ${theme.spacing(4)}px`,
  },
  afterDivider: {
    marginLeft: -1,
  },
  avatar: {
    background: reliefTeal,
    color: white,
    marginLeft: "auto",
    marginRight: "auto",
  },
  timeline: {
    marginTop: 2,
  },
}));

export const VideoVisitDetails: FunctionComponent<VideoVisitDetailsProps> = ({
  visit: simpleVisit,
}) => {
  const classes = useCardStyles();
  const [{ visit, isFetching }, { accept }] = useVideoVisitService(simpleVisit);
  const [confirm, changeConfirmation] = useState(false);

  const lastVisit = useMemo(() => {
    const history = visit.pastVisits ? [...visit.pastVisits] : [];

    // sort in reverse chronological order
    history.sort((one, two) => {
      if (one.createdAt > two.createdAt) {
        return -1;
      }

      if (one.createdAt < two.createdAt) {
        return 1;
      }

      return 0;
    });

    return history.length > 0 ? history[0] : undefined;
  }, [visit]);

  // const isCompletedState = visit.status === VideoVisitStatus.COMPLETED;
  const isOpenState = visit.status === VideoVisitStatus.OPEN;

  const handleAccept = () => {
    accept();
  };

  const toggleConfirmation = () => {
    changeConfirmation(!confirm);
  };

  const actions: ReactNode = (
    <Grid container spacing={4}>
      <Hidden smDown>
        <Grid item xs />
      </Hidden>
      <Grid item xs={12} md>
        {!isOpenState && (
          <Button
            variant="outlined"
            component={Link}
            fullWidth
            to={createRoute(PATH_CONSULTATION, { id: visit.id })}
          >
            View Chart
          </Button>
        )}
      </Grid>
      {isOpenState ? (
        <Grid item xs md>
          <LoadingButton
            fullWidth
            onClick={toggleConfirmation}
            isLoading={isFetching}
          >
            Accept
          </LoadingButton>
        </Grid>
      ) : null}
    </Grid>
  );

  return (
    <VisitDetailCard
      address={
        typeof visit.household === "string"
          ? undefined
          : visit.household?.address?.summary
      }
      dob={typeof visit.patient === "string" ? undefined : visit.patient?.dob}
      gender={
        typeof visit.patient === "string" ? undefined : visit.patient?.gender
      }
      name={visit.patientName ?? ""}
      payment={visit.payment}
      phone={
        typeof visit.household === "string"
          ? undefined
          : visit.household?.phones[0].number
      }
    >
      <CardContent className={classes.content}>
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <DefinedProperty title="CC">
              {visit.primaryComplaint ?? emptyValue}
            </DefinedProperty>
          </Grid>
          <Grid item xs={4}>
            <DefinedProperty title="Duration">
              {visit.complaintDuration ?? emptyValue}
            </DefinedProperty>
          </Grid>
          <Grid item xs={12}>
            <DefinedProperty title="Symptoms">
              {visit.symptoms && visit.symptoms.length > 0
                ? visit.symptoms.map((symptom) => symptom.name).join(", ")
                : emptyValue}
            </DefinedProperty>
          </Grid>
          <Grid item xs={12}>
            <DefinedProperty title="Problem List">
              {visit.healthHistory?.conditions &&
              visit.healthHistory?.conditions.length > 0
                ? visit.healthHistory?.conditions
                    .map((condition) => condition.name)
                    .join(", ")
                : emptyValue}
            </DefinedProperty>
          </Grid>
          <Grid item xs={12}>
            <DefinedProperty title="Meds">
              {visit.healthHistory?.medications &&
              visit.healthHistory?.medications.length > 0
                ? visit.healthHistory?.medications
                    .map((medication) => medication.name)
                    .join(", ")
                : emptyValue}
            </DefinedProperty>
          </Grid>
          <Grid item xs={12}>
            <DefinedProperty title="Allergies">
              {visit.healthHistory?.allergies &&
              visit.healthHistory?.allergies.length > 0
                ? visit.healthHistory?.allergies
                    .map((allergy) => allergy.name)
                    .join(", ")
                : emptyValue}
            </DefinedProperty>
          </Grid>
          <Grid item xs={4}>
            <DefinedProperty title="Last Encounter">
              {lastVisit ? renderDate(lastVisit.createdAt) : emptyValue}
            </DefinedProperty>
          </Grid>
          <Grid item xs={4}>
            <DefinedProperty title="Provider">
              {visit.providerName ?? emptyValue}
            </DefinedProperty>
          </Grid>
          <Grid item xs={4}>
            <DefinedProperty title="DX">
              {visit.diagnoses && visit.diagnoses.length > 0
                ? visit.diagnoses.map((diagnosis) => diagnosis.code).join(", ")
                : emptyValue}
            </DefinedProperty>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <CardActions className={classes.content}>{actions}</CardActions>
      <ConfirmationModal
        instructions="Clicking Yes will start the visit."
        open={confirm}
        onAccept={handleAccept}
        onClose={toggleConfirmation}
        title="Are you sure?"
      />
    </VisitDetailCard>
  );
};
