import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  makeStyles,
  Slide,
  Dialog,
  Box,
} from "@material-ui/core";
// eslint-disable-next-line import/no-internal-modules
import { TransitionProps } from "@material-ui/core/transitions";
import React, { FunctionComponent, ReactNode, useState } from "react";

import { RoomStateType, useRoomState, useVideoContext } from "../../hooks";

import { Controls } from "./controls";
import { LocalVideoPreview } from "./local-preview";
import { MainParticipant } from "./main-participant";
import { ParticipantInfo } from "./participant-info";

const useRoomStyles = makeStyles({
  actions: {
    justifyContent: "space-evenly",
  },
  cardFullScreen: {
    backgroundColor: "#1F2124",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  dialog: {
    padding: 0,
  },
  localPreviewContainer: {
    width: "15%",
    height: "25%",
    position: "absolute",
    bottom: "17%",
    right: "4%",
  },
});

export const VideoRoomCard: FunctionComponent = () => {
  const classes = useRoomStyles();
  const roomState = useRoomState();
  const { isFullscreen } = useVideoContext();

  let display: ReactNode = <MainParticipant />;
  if (RoomStateType.disconnected === roomState) {
    display = <LocalVideoPreview />;
  }

  const basicHeader = isFullscreen ? null : (
    <CardHeader
      titleTypographyProps={{ variant: "overline" }}
      title="Video Preview"
    />
  );
  return (
    <Card className={isFullscreen ? classes.cardFullScreen : ""}>
      {RoomStateType.connected === roomState ? (
        <ParticipantInfo />
      ) : (
        basicHeader
      )}
      <CardContent style={{ textAlign: "center" }}>{display}</CardContent>
      <CardActions className={classes.actions}>
        <Controls />
      </CardActions>
    </Card>
  );
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const VideoRoom: FunctionComponent = () => {
  const classes = useRoomStyles();
  const roomState = useRoomState();
  const { isFullscreen, setFullscreen } = useVideoContext();
  const renderedVidoRoomCard = <VideoRoomCard />;
  return (
    <>
      {!isFullscreen && renderedVidoRoomCard}

      <Dialog
        fullScreen
        open={isFullscreen}
        onClose={() => setFullscreen(false)}
        TransitionComponent={Transition}
        PaperProps={{ className: classes.dialog }}
      >
        {renderedVidoRoomCard}
        {roomState === RoomStateType.connected && (
          <Box className={classes.localPreviewContainer}>
            <LocalVideoPreview />
          </Box>
        )}
      </Dialog>
    </>
  );
};
