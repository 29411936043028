import { Fullscreen, FullscreenExit } from "@material-ui/icons";
import React, { FunctionComponent } from "react";

import { ControlButton } from "./control-button";

export interface ControlFullscreenProps {
  isFullscreen: boolean;
  setFullscreen: (value: boolean) => void;
  className?: string;
}

export const ControlFullscreen: FunctionComponent<ControlFullscreenProps> = ({
  isFullscreen,
  setFullscreen,
  className,
}) => {
  return (
    <ControlButton
      onClick={() => setFullscreen(!isFullscreen)}
      tooltip={isFullscreen ? "Exit Fullscreen" : "Fullscreen"}
      className={className}
    >
      {isFullscreen ? (
        <FullscreenExit color="error" />
      ) : (
        <Fullscreen color="primary" />
      )}
    </ControlButton>
  );
};
