import {
  Checkbox,
  FormControlLabel,
  Grid,
  FormControl,
  FormLabel,
  CircularProgress,
} from "@material-ui/core";
import React, {
  FunctionComponent,
  useState,
  useMemo,
  ChangeEventHandler,
} from "react";

import { EditableCard } from "../../components";
// mocked data
import { useGetMedicalSymptoms, VideoVisit, Symptom } from "../../core";

const dissoc = (key: string, obj: Record<string, Symptom>) => {
  const copy = { ...obj };
  delete copy[key];
  return copy;
};

const buildSymptomKey = (group: string, name: string): string =>
  `${group}-${name.replace(" ", "_")}`;

// types

interface VisitDetail {
  onChange?: (visitInfo: Partial<VideoVisit>) => void;
  visit: VideoVisit;
}

export const ReviewOfSystems: FunctionComponent<VisitDetail> = ({
  onChange = () => null,
  visit,
}) => {
  // states
  const initialSymptomMap: Record<string, Symptom> = useMemo(
    () =>
      visit?.symptoms.reduce(
        (acc, val) => ({ ...acc, [buildSymptomKey(val.group, val.name)]: val }),
        {},
      ),
    [visit],
  );
  const { data: symptoms = [] as Symptom[], isLoading } =
    useGetMedicalSymptoms();

  const [symptomsMap, setSymptomsMap] =
    useState<Record<string, Symptom>>(initialSymptomMap);
  // functions
  const handleSave = () => {
    onChange({ symptoms: Object.values(symptomsMap) });
  };

  const handleCancel = () => {
    setSymptomsMap(initialSymptomMap);
  };

  const handleChange =
    ({
      id,
      name,
      group,
    }: Partial<Symptom>): ChangeEventHandler<HTMLInputElement> =>
    (event) => {
      setSymptomsMap((prevValue: Record<string, Symptom>) =>
        event.target.checked
          ? {
              ...prevValue,
              [buildSymptomKey(group!, name!)]: {
                id: id!,
                group: group!,
                description: "description",
                name: name!,
              },
            }
          : dissoc(buildSymptomKey(group!, name!), prevValue),
      );
    };

  const dataSortedByGroups: Record<string, Array<Symptom>> = useMemo(
    () =>
      symptoms.reduce((acc: { [key: string]: Array<Symptom> }, v: Symptom) => {
        acc[v.group] = acc[v.group] || [];
        acc[v.group] = acc[v.group].concat(v);

        return acc;
      }, {}),
    [symptoms],
  );

  const symptomCheckboxes = (disabled?: boolean) =>
    Object.entries(dataSortedByGroups).map(([group, values]) => (
      <Grid item xs={6} lg={4} key={`${group}-container`}>
        <FormControl component="fieldset">
          <FormLabel component="legend">{group}</FormLabel>
          {values.map(({ id, name }) => (
            <FormControlLabel
              key={buildSymptomKey(group, name)}
              control={
                <Checkbox
                  checked={Boolean(symptomsMap[buildSymptomKey(group, name)])}
                  onChange={handleChange({ id, name, group })}
                  inputProps={{ "aria-label": "primary checkbox" }}
                  disabled={disabled}
                />
              }
              label={name}
            />
          ))}
        </FormControl>
      </Grid>
    ));

  return (
    <EditableCard
      content={
        <Grid container spacing={2}>
          {isLoading ? <CircularProgress /> : symptomCheckboxes(true)}
        </Grid>
      }
      editContent={
        <Grid container spacing={2}>
          {isLoading ? <CircularProgress /> : symptomCheckboxes()}
        </Grid>
      }
      label="Symptoms"
      onCancel={handleCancel}
      onSave={handleSave}
    />
  );
};
