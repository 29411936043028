import { Mic, MicOff } from "@material-ui/icons";
import React, { FunctionComponent } from "react";

import {
  RoomStateType,
  useAudioToggle,
  useRoomState,
  useVideoContext,
} from "../../hooks";

import { AudioLevelIndicator } from "./audio-level-indicator";
import { ControlButton } from "./control-button";

interface ControlAudioProps {
  className?: string;
}

export const ControlAudio: FunctionComponent<ControlAudioProps> = ({
  className,
}) => {
  const { room } = useVideoContext();
  const roomState = useRoomState();
  const [isAudioEnabled, toggleAudio] = useAudioToggle();

  let audioToggle = isAudioEnabled ? (
    <Mic color="primary" />
  ) : (
    <MicOff color="error" />
  );

  if (roomState === RoomStateType.connected && isAudioEnabled) {
    audioToggle = (
      <AudioLevelIndicator
        participant={room.localParticipant}
        showTooltip={false}
      />
    );
  }

  return (
    <ControlButton
      onClick={toggleAudio}
      tooltip={isAudioEnabled ? "Turn off audio" : "Turn audio on"}
      className={className}
    >
      {audioToggle}
    </ControlButton>
  );
};
