import { CardHeader, Tooltip, makeStyles } from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { useIsParticipantReconnecting, useMainParticipant } from "../../hooks";

import { AudioLevelIndicator } from "./audio-level-indicator";
import { NetworkQualityIndicator } from "./network-quality-indicator";
import { VideoStatusIndicator } from "./video-status-indicator";

const useStyles = makeStyles(({ palette }) => ({
  fullscreenTitle: {
    color: "#0099cc",
  },
}));

export const ParticipantInfo: FunctionComponent = () => {
  const classes = useStyles();
  const participant = useMainParticipant();
  const isReconnecting = useIsParticipantReconnecting(participant);

  let networkQuality = <NetworkQualityIndicator participant={participant} />;
  if (networkQuality) {
    networkQuality = (
      <Tooltip
        title={`Participant is ${
          isReconnecting ? "reconnecting" : "connected"
        }`}
      >
        {networkQuality}
      </Tooltip>
    );
  }

  return (
    <CardHeader
      action={
        <>
          <VideoStatusIndicator participant={participant} />
          <AudioLevelIndicator participant={participant} />
          {networkQuality}
        </>
      }
      titleTypographyProps={{
        variant: "overline",
        className: classes.fullscreenTitle,
      }}
      title={`${participant.identity
        .slice(0, 1)
        .toUpperCase()}${participant.identity.slice(1)}`}
    />
  );
};
