import { Machine, sendParent } from "xstate";

import { AUTHENTICATION_MACHINE, UPDATE_EVENT } from "../../constants";

import {
  clearContext,
  saveCredentials,
  saveEmail,
  saveError,
  saveMobile,
  saveNewPassword,
  saveUser,
} from "./actions";
import {
  AUTHENTICATED,
  AUTHENTICATING,
  FAILED,
  FORGET_EMAIL,
  FORGET_PASSWORD,
  LOGIN,
  PASSWORD_RESET,
  RESET_PASSWORD,
  RESET,
  RESETTING,
  SENDING_EMAIL,
  SENDING_RESET,
  SENT_EMAIL,
  SENT_RESET,
  UNAUTHENTICATED,
} from "./constants";
import {
  createForgetEmailPromise,
  createForgetPasswordPromise,
  createLoginPromise,
  createResetPasswordPromise,
} from "./promises";
import {
  AuthenticationContext,
  AuthenticationEvents,
  AuthenticationSchema,
} from "./types";

export const authMachine = Machine<
  AuthenticationContext,
  AuthenticationSchema,
  AuthenticationEvents
>(
  {
    id: AUTHENTICATION_MACHINE,
    initial: UNAUTHENTICATED,
    context: {
      confirmation: "",
      email: "",
      mobile: "",
      password: "",
      token: "",
      errors: "",
      code: "",
    },
    states: {
      [UNAUTHENTICATED]: {
        on: {
          [LOGIN]: {
            target: AUTHENTICATING,
            actions: "saveCredentials",
          },
          [FORGET_EMAIL]: {
            target: SENDING_EMAIL,
            actions: "saveMobile",
          },
          [FORGET_PASSWORD]: {
            target: SENDING_RESET,
            actions: "saveEmail",
          },
          [RESET_PASSWORD]: {
            target: RESETTING,
            actions: "saveNewPassword",
          },
        },
      },
      [SENDING_EMAIL]: {
        invoke: {
          src: createForgetEmailPromise,
          id: "forget-email",
          onDone: {
            target: SENT_EMAIL,
            actions: "clearContext",
          },
        },
      },
      [SENT_EMAIL]: {
        entry: sendParent(UPDATE_EVENT),
        exit: sendParent(UPDATE_EVENT),
        after: {
          1000: UNAUTHENTICATED,
        },
      },
      [SENDING_RESET]: {
        invoke: {
          src: createForgetPasswordPromise,
          id: "forget-password",
          onDone: {
            target: SENT_RESET,
            actions: "clearContext",
          },
        },
      },
      [SENT_RESET]: {
        entry: sendParent(UPDATE_EVENT),
        exit: sendParent(UPDATE_EVENT),
        after: {
          1000: UNAUTHENTICATED,
        },
      },
      [RESETTING]: {
        invoke: {
          src: createResetPasswordPromise,
          id: "reset-password",
          onDone: {
            target: PASSWORD_RESET,
            actions: "clearContext",
          },
          onError: {
            target: FAILED,
            actions: ["clearContext", "saveError"],
          },
        },
      },
      [PASSWORD_RESET]: {
        entry: sendParent(UPDATE_EVENT),
        after: {
          1000: UNAUTHENTICATED,
        },
      },
      [FAILED]: {
        entry: sendParent(UPDATE_EVENT, { delay: 200 }),
        exit: sendParent(UPDATE_EVENT, { delay: 200 }),
        on: {
          [RESET]: {
            target: UNAUTHENTICATED,
            actions: "clearContext",
          },
          [LOGIN]: {
            target: AUTHENTICATING,
            actions: "saveCredentials",
          },
        },
      },
      [AUTHENTICATING]: {
        invoke: {
          src: createLoginPromise,
          id: "login",
          onDone: {
            target: AUTHENTICATED,
            actions: ["clearContext", "saveUser"],
          },
          onError: {
            target: FAILED,
            actions: ["clearContext", "saveError"],
          },
        },
      },
      [AUTHENTICATED]: {
        type: "final",
        data: ({ token, user }) => ({
          token,
          user,
        }),
      },
    },
  },
  {
    actions: {
      clearContext,
      saveCredentials,
      saveEmail,
      saveError,
      saveMobile,
      saveNewPassword,
      saveUser,
    },
  },
);
