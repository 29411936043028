import {
  Grid,
  Link,
  TextField,
  Typography,
  makeStyles,
} from "@material-ui/core";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from "history";
import React, {
  FormEventHandler,
  FunctionComponent,
  useState,
  useMemo,
} from "react";
import { Link as RouterLink, Redirect, useLocation } from "react-router-dom";

import {
  ActionButton,
  Alert,
  FormCard,
  NotificationTrigger,
  PageTitle,
  PasswordInput,
  Success,
} from "../components";
import { supportEmail } from "../config";
import {
  AUTHENTICATION_MACHINE,
  PATH_HOME,
  PATH_FORGOT_EMAIL,
  PATH_FORGOT_PASSWORD,
} from "../constants";
import { useAnalytics, validateEmail, validateMinLength } from "../core";
import { curryValidator } from "../helpers";
import { useValidatedState } from "../hooks";
import { AuthenticationLayout } from "../layouts";
import { useAppService } from "../state";

import { AuthenticationService, useAuthService } from "./state";

interface LoginLocationState {
  didResetPassword?: boolean;
  didSendEmail?: boolean;
  from?: Location;
}

const useLoginStyles = makeStyles((theme) => ({
  helperText: {
    margin: `${theme.spacing(1)}px 0`,
    textAlign: "right",
  },
}));

export const LoginPage: FunctionComponent = () => {
  const classes = useLoginStyles();
  const { track } = useAnalytics();

  const [{ isLoggedIn }, { [AUTHENTICATION_MACHINE]: authentication }] =
    useAppService();

  const location = useLocation<LoginLocationState>();

  const [{ didFail, errors }, { login, reset }] = useAuthService(
    authentication as AuthenticationService,
  );
  const [attempts, setAttempts] = useState(0);
  const [dismissed, setDismissed] = useState(false);
  const [code, setCode] = useState("");
  const [email, isEmailValid, changeEmail] = useValidatedState(
    "",
    curryValidator<string>(validateEmail),
    true,
  );

  const [password, isPasswordValid, changePassword] = useValidatedState(
    "",
    curryValidator<string>(validateMinLength, false),
    false,
  );

  const canSubmit = isEmailValid && isPasswordValid && email.length > 0;

  const handleSubmit: FormEventHandler<HTMLFormElement & HTMLDivElement> = (
    event,
  ) => {
    event.preventDefault();
    setAttempts(attempts + 1);
    login(email, password, code);
  };
  const requiresTwoFactor = useMemo(
    () => (errors ?? "").includes("2FA"),
    [errors],
  );

  const handleDismissError = () => {
    if (!requiresTwoFactor) reset();
  };

  const handleDismissSuccess = () => {
    setDismissed(true);
  };

  let message;
  let handleDismiss: () => void = () => undefined;

  if (didFail && !requiresTwoFactor) {
    track("login.failed", { attempts, email });
    handleDismiss = handleDismissError;
    message = (
      <Alert
        message="Incorrect Credentials. Please check your email address and password and try logging in again."
        onDismiss={handleDismissError}
      />
    );
  } else if (location.state?.didResetPassword) {
    handleDismiss = handleDismissSuccess;
    message = (
      <Success
        message="Your password has been reset."
        onDismiss={handleDismissSuccess}
      />
    );
  } else if (location.state?.didSendEmail) {
    handleDismiss = handleDismissSuccess;
    message = (
      <Success
        message="A text message was sent to your phone."
        onDismiss={handleDismissSuccess}
      />
    );
  }

  if (isLoggedIn) {
    track("login.succeed", { attempts, email });
    return <Redirect to={location.state?.from ?? PATH_HOME} />;
  }

  return (
    <AuthenticationLayout
      rightSidebar={
        <Typography align="right" variant="body2" display="block">
          Don&apos;t have an account?{" "}
          <Link href={`mailto:${supportEmail}`}>Contact us</Link>
        </Typography>
      }
    >
      <PageTitle title="Login" />
      <FormCard
        title="Log in to Relief Telemed"
        instructions="Enter your login credentials."
        elevation={0}
        onSubmit={handleSubmit}
        actions={
          <ActionButton type="submit" disabled={!canSubmit}>
            Login
          </ActionButton>
        }
      >
        <Grid item xs={12}>
          <TextField
            autoFocus
            error={!isEmailValid}
            helperText={
              <Link component={RouterLink} to={PATH_FORGOT_EMAIL}>
                Forgot Email?
              </Link>
            }
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            autoComplete="section-login username"
            id="email"
            label="Email"
            onChange={changeEmail}
            required
            value={email}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordInput
            helperText={
              <Link component={RouterLink} to={PATH_FORGOT_PASSWORD}>
                Forgot Password?
              </Link>
            }
            FormHelperTextProps={{
              className: classes.helperText,
            }}
            autoComplete="section-login current-password"
            id="password"
            label="Password"
            onChange={changePassword}
            required
            value={password}
          />
        </Grid>
        {(errors ?? "").includes("2FA") ? (
          <Grid item xs={12}>
            <TextField
              autoFocus
              error={!isEmailValid}
              id="token"
              label="2Fa Token"
              onChange={(event) => setCode(event?.target.value)}
              helperText={errors}
              value={code}
            />
          </Grid>
        ) : null}
      </FormCard>
      <NotificationTrigger
        open={Boolean(message) && !dismissed}
        onClose={handleDismiss}
      >
        {message}
      </NotificationTrigger>
    </AuthenticationLayout>
  );
};
