import { Grid, TextField } from "@material-ui/core";
import { validateEmail, usePatchUser, User } from "@relieftelemed/platform";
import React, { FormEvent, FunctionComponent } from "react";

import { ActionButton, FormCard } from "../../components";
import { curryValidator } from "../../helpers";
import { useValidatedState } from "../../hooks";

export interface UserInformationFormProps {
  user: User;
  onUserUpdated: (updatedUser: User) => void;
}

export const UserInformationForm: FunctionComponent<UserInformationFormProps> =
  ({ user, onUserUpdated }) => {
    const [email, isEmailValid, changeEmail] = useValidatedState(
      user.email,
      curryValidator<string>(validateEmail),
    );

    const { mutate, isError, isSuccess, reset } = usePatchUser(user!.id);

    const handleSubmit = (e: FormEvent) => {
      e.preventDefault();
      mutate(
        {
          email,
        },
        {
          onSuccess: (_updatedUserData) => {
            onUserUpdated({ ...user, email });
          },
        },
      );
    };

    return (
      <FormCard
        elevation={0}
        title="Update Email"
        onSubmit={handleSubmit}
        actions={
          <ActionButton type="submit" disabled={!isEmailValid}>
            Save
          </ActionButton>
        }
      >
        <Grid item xs={12} md={6}>
          <TextField
            id="email"
            onChange={changeEmail}
            value={email}
            label="Email"
            required
            autoFocus
            error={!isEmailValid}
          />
        </Grid>
      </FormCard>
    );
  };
