import { makeStyles } from "@material-ui/core";
import React, { FunctionComponent, useState, useEffect, useMemo } from "react";

import { RoomStateType, useRoomState, useVideoContext } from "../../hooks";

import { ControlAudio } from "./control-audio";
import { ControlConnect } from "./control-connect";
import { ControlDisconnect } from "./control-disconnect";
import { ControlFullscreen } from "./control-full-screen";
import { ControlQuality } from "./control-quality";
import { ControlRequeue } from "./control-requeue";
import { ControlVideo } from "./control-video";

const useStyles = makeStyles(() => ({
  fullscreenControls: {
    backgroundColor: "#434649",
  },
}));

export const Controls: FunctionComponent = () => {
  const classes = useStyles();
  const roomState = useRoomState();
  const [isRetry, setRetry] = useState(false);

  const { isFullscreen, setFullscreen } = useVideoContext();
  const controlClassNameStyles = useMemo(
    () => (isFullscreen ? classes.fullscreenControls : ""),
    [isFullscreen, classes],
  );

  useEffect(() => {
    if (roomState === RoomStateType.connected) {
      setRetry(true);
    }
  }, [roomState]);

  let connectButton = (
    <ControlConnect isRetry={isRetry} className={controlClassNameStyles} />
  );
  if (roomState === RoomStateType.connected) {
    connectButton = <ControlDisconnect className={controlClassNameStyles} />;
  }

  let requeue = null;
  if (isRetry && roomState === RoomStateType.disconnected) {
    requeue = <ControlRequeue />;
  }

  return (
    <>
      <ControlAudio className={controlClassNameStyles} />
      {requeue}
      {connectButton}
      <ControlFullscreen
        isFullscreen={isFullscreen}
        setFullscreen={setFullscreen}
        className={controlClassNameStyles}
      />
      <ControlVideo className={controlClassNameStyles} />
      <ControlQuality />
    </>
  );
};
