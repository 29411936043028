import { Grid, TextField } from "@material-ui/core";
import React, { FormEventHandler, FunctionComponent } from "react";
import { Redirect, useParams } from "react-router-dom";

import {
  ActionButton,
  FormCard,
  PageTitle,
  PasswordInput,
} from "../components";
import { AUTHENTICATION_MACHINE, PATH_LOGIN } from "../constants";
import { useAnalytics, validateConfirmation, validatePassword } from "../core";
import { curryConfirmationValidator, curryValidator } from "../helpers";
import { useValidatedConfirmation } from "../hooks";
import { AuthenticationLayout } from "../layouts";
import { useAppService } from "../state";

import { AuthenticationService, useAuthService } from "./state";

interface ResetPasswordParams {
  uid: string;
  token: string;
}

export const ResetPasswordPage: FunctionComponent = () => {
  const [, { [AUTHENTICATION_MACHINE]: authentication }] = useAppService();
  const [{ didResetPassword }, { resetPassword }] = useAuthService(
    authentication as AuthenticationService,
  );
  const { uid, token } = useParams<ResetPasswordParams>();
  // const { track } = useAnalytics();
  const [
    password,
    confirmation,
    isPasswordValid,
    isConfirmationValid,
    changePassword,
    changeConfirmation,
  ] = useValidatedConfirmation(
    "",
    curryValidator<string>(validatePassword),
    curryConfirmationValidator(validateConfirmation),
  );

  if (didResetPassword) {
    // track("auth.resetPassword", { email });
    return (
      <Redirect
        to={{
          pathname: PATH_LOGIN,
          state: {
            didResetPassword: true,
          },
        }}
      />
    );
  }

  const canSubmit = isPasswordValid && isConfirmationValid;

  const handleSubmit: FormEventHandler<HTMLFormElement & HTMLDivElement> = (
    event,
  ) => {
    event.preventDefault();
    resetPassword({
      password,
      confirmation,
      uid,
      token,
    });
  };

  return (
    <AuthenticationLayout>
      <PageTitle title="Reset Password" />
      <FormCard
        title="Reset Password"
        instructions="Please create a new password."
        onSubmit={handleSubmit}
        elevation={0}
        actions={
          <ActionButton type="submit" disabled={!canSubmit}>
            Submit
          </ActionButton>
        }
      >
        <Grid item xs={12}>
          <PasswordInput
            error={!isPasswordValid}
            id="password"
            label="Password"
            onChange={changePassword}
            required
            value={password}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            error={!isConfirmationValid}
            helperText={
              isConfirmationValid ? undefined : "Passwords do not match"
            }
            id="password-confirmation"
            label="Confirm Password"
            onChange={changeConfirmation}
            required
            type="password"
            value={confirmation}
          />
        </Grid>
      </FormCard>
    </AuthenticationLayout>
  );
};
