import { IconButton, makeStyles, Tooltip } from "@material-ui/core";
import React, { FunctionComponent } from "react";

interface ControlProps {
  disabled?: boolean;
  onClick?: () => void;
  tooltip?: string;
  className?: string;
}

const useControlsStyles = makeStyles((theme) => ({
  control: {
    borderWidth: 1,
    borderColor: theme.palette.divider,
    borderStyle: "solid",
  },
}));

export const ControlButton: FunctionComponent<ControlProps> = ({
  children,
  disabled = false,
  onClick,
  tooltip,
  className = "",
}) => {
  const classes = useControlsStyles();

  const button = (
    <IconButton
      className={`${classes.control} ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {children}
    </IconButton>
  );

  if (!tooltip) {
    return button;
  }

  return <Tooltip title={tooltip}>{button}</Tooltip>;
};
