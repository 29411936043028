import React, {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import Video, {
  ConnectOptions,
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalTrack,
  LocalVideoTrack,
  Room,
  TwilioError,
} from "twilio-video";

import { logger } from "../../core";
import {
  isLocalTrackAudio,
  isLocalTrackDefined,
  isLocalTrackVideo,
  isMobile,
  VideoContext,
} from "../../helpers";
import { RoomStateType, RoomTrackEvent } from "../../hooks";

import { BackgroundHandler } from "./background-handler";
import { SelectedParticipantProvider } from "./selected-participant-provider";

import { EventEmitter } from "events";

const noOpRoom = () => new EventEmitter() as Room;

const DEFAULT_VIDEO_CONSTRAINTS: MediaStreamConstraints["video"] = {
  height: 1280,
  width: 720,
  frameRate: 24,
};

interface VideoProviderProps {
  options?: ConnectOptions;
  onError?: (error: TwilioError) => void;
  onConnect?: () => void;
  onDisconnect?: () => void;
  children: ReactNode;
  token?: string;
}

export const VideoProvider: FunctionComponent<VideoProviderProps> = ({
  options,
  children,
  onConnect = () => {},
  onError = () => {},
  onDisconnect = () => {},
  token,
}) => {
  const onErrorCallback = (error: TwilioError) => {
    logger.error(error.message, error);
    onError(error);
  };
  const [room, setRoom] = useState(noOpRoom());
  const [isConnecting, setIsConnecting] = useState(false);
  const [isFullscreen, setFullscreen] = useState(false);

  const localTracksRef = useRef<LocalTrack[]>([]);

  const [audioTrack, setAudioTrack] = useState<LocalAudioTrack>();
  const [videoTrack, setVideoTrack] = useState<LocalVideoTrack>();
  const [isAcquiringLocalTracks, setIsAcquiringLocalTracks] = useState(false);

  const getLocalAudioTrack = useCallback((deviceId?: string) => {
    const audioOptions: CreateLocalTrackOptions = {};

    if (deviceId) {
      audioOptions.deviceId = { exact: deviceId };
    }

    return Video.createLocalAudioTrack(audioOptions).then((newTrack) => {
      setAudioTrack(newTrack);

      return newTrack;
    });
  }, []);

  const getLocalVideoTrack = useCallback(
    (newOptions?: CreateLocalTrackOptions) => {
      const videoOptions: CreateLocalTrackOptions = {
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        name: `camera-${Date.now()}`,
        ...newOptions,
      };

      return Video.createLocalVideoTrack(videoOptions).then((newTrack) => {
        setVideoTrack(newTrack);
        return newTrack;
      });
    },
    [],
  );

  const removeLocalVideoTrack = useCallback(() => {
    if (videoTrack) {
      videoTrack.stop();
      setVideoTrack(undefined);
    }
  }, [videoTrack]);

  useEffect(() => {
    setIsAcquiringLocalTracks(true);
    Video.createLocalTracks({
      video: {
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        name: `camera-${Date.now()}`,
      },
      audio: true,
    })
      .then((tracks) => {
        const localVideoTrack = tracks.find(isLocalTrackVideo);
        if (localVideoTrack) {
          setVideoTrack(localVideoTrack);
        }

        const localAudioTrack = tracks.find(isLocalTrackAudio);
        if (localAudioTrack) {
          setAudioTrack(localAudioTrack);
        }
      })
      .finally(() => setIsAcquiringLocalTracks(false));
  }, []);

  const localTracks = [audioTrack, videoTrack].filter(isLocalTrackDefined);

  useEffect(() => {
    // Queue toggles that might happen during establising the connection
    localTracksRef.current = localTracks;
  }, [localTracks]);

  useEffect(() => {
    const { localParticipant } = room;
    const handleError = (_room: Room, error: TwilioError) => {
      if (error) {
        logger.error(error.message, error);
        onError(error);
      }
    };

    room.on(RoomStateType.disconnected, handleError);
    room.on(RoomStateType.disconnected, onDisconnect);

    if (localParticipant) {
      localParticipant.on(RoomTrackEvent.failed, handleError);
    }

    return () => {
      room.off(RoomStateType.disconnected, handleError);
      room.off(RoomStateType.disconnected, onDisconnect);
      if (localParticipant) {
        localParticipant.off(RoomTrackEvent.failed, handleError);
      }
    };
  }, [room, onDisconnect, onError]);

  const connect = useCallback(() => {
    setIsConnecting(true);

    if (!token) {
      return Promise.resolve();
    }

    return Video.connect(token, { ...options, tracks: [] }).then(
      (newRoom) => {
        setRoom(newRoom);
        onConnect();
        const disconnect = () => newRoom.disconnect();
        newRoom.localParticipant.setNetworkQualityConfiguration({
          local: 2,
          remote: 1,
        });

        newRoom.once(RoomStateType.disconnected, () => {
          setTimeout(() => setRoom(noOpRoom()));
          window.removeEventListener("beforeunload", disconnect);

          if (isMobile) {
            window.removeEventListener("pagehide", disconnect);
          }
        });

        localTracksRef.current.forEach((track) =>
          // Publish tracks manually so that we can set priority for them
          newRoom.localParticipant.publishTrack(track, {
            priority: track.kind === "video" ? "low" : "standard",
          }),
        );

        setIsConnecting(false);

        // Disconnect from the room when a user closes their browser
        window.addEventListener("beforeunload", disconnect);

        if (isMobile) {
          // Disconnect from the room when a mobile user switches apps
          window.addEventListener("pagehide", disconnect);
        }

        return newRoom.participants;
      },
      (error: TwilioError) => {
        onError(error);
        setIsConnecting(false);
      },
    );
  }, [options, onConnect, onError, token]);

  const disconnect = () => {
    if (room.disconnect) {
      room.disconnect();
    }
  };

  return (
    <VideoContext.Provider
      value={{
        room,
        localTracks,
        isConnecting,
        onError: onErrorCallback,
        onDisconnect,
        getLocalVideoTrack,
        getLocalAudioTrack,
        connect,
        disconnect,
        isAcquiringLocalTracks,
        removeLocalVideoTrack,
        isFullscreen,
        setFullscreen,
      }}
    >
      <SelectedParticipantProvider room={room}>
        {children}
      </SelectedParticipantProvider>
      <BackgroundHandler />
    </VideoContext.Provider>
  );
};
