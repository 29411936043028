import {
  Interpreter,
  State,
  DoneInvokeEvent,
  ErrorExecutionEvent,
} from "xstate";

import { User } from "../../core";

export interface CredentialData {
  email: string;
  password: string;
  code?: string;
}

export interface ResetPasswordData extends CredentialData {
  confirmation: string;
  token: string;
}

export interface MobileResetPasswordData {
  password: string;
  confirmation: string;
  token: string;
  uid: string;
}

export interface AuthenticationContext extends ResetPasswordData {
  errors: string;
  mobile: string;
  user?: User;
  uid?: string;
}

export interface AuthenticationSchema {
  states: {
    unauthenticated: {};
    authenticating: {};
    sendingEmail: {};
    sentEmail: {};
    sendingReset: {};
    sentReset: {};
    resetting: {};
    resetPassword: {};
    failed: {};
    authenticated: {};
  };
}

// Events

export interface ForgetEmailEvent {
  type: "AUTH.FORGET_EMAIL";
  mobile: string;
}

export interface ForgetPasswordEvent {
  type: "AUTH.FORGET_PASSWORD";
  email: string;
}

export interface LoginEvent extends CredentialData {
  type: "AUTH.LOGIN";
}

export type RequestDoneEvent = { type: "AUTH.DONE" };

export interface ResetEvent {
  type: "AUTH.RESET";
}

export interface ResetPasswordEvent extends MobileResetPasswordData {
  type: "AUTH.RESET_PASSWORD";
}

export interface SuccessResponse {
  token: string;
  user: User;
}

// Aggregates

export type AuthenticationEvents =
  | ErrorExecutionEvent
  | ForgetEmailEvent
  | ForgetPasswordEvent
  | LoginEvent
  | RequestDoneEvent
  | ResetEvent
  | ResetPasswordEvent
  | DoneInvokeEvent<SuccessResponse>;

export const isDoneEvent = <TData = {}>(
  event: AuthenticationEvents,
): event is DoneInvokeEvent<SuccessResponse> =>
  (event as DoneInvokeEvent<TData>).data !== undefined;

export type AutheticationState = State<
  AuthenticationContext,
  AuthenticationEvents
>;

export type AuthenticationService = Interpreter<
  AuthenticationContext,
  AuthenticationSchema,
  AuthenticationEvents
>;

// Hooks

type SendLogin = (email: string, password: string, code?: string) => void;

type SendForgotEmail = (mobile: string) => void;

type SendForgotPassword = (email: string) => void;

type SendPasswordReset = (payload: MobileResetPasswordData) => void;

type SendReset = () => void;

interface AuthenticationHookActions {
  forgetEmail: SendForgotEmail;
  forgetPassword: SendForgotPassword;
  login: SendLogin;
  reset: SendReset;
  resetPassword: SendPasswordReset;
}

interface AuthenticationHookSelectors {
  errors: string;
  didFail: boolean;
  didResetPassword: boolean;
  didSendEmail: boolean;
  didSendReset: boolean;
}

export type AuthServiceHook = [
  AuthenticationHookSelectors,
  AuthenticationHookActions,
];
