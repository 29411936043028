import { Clinic, User } from "@relieftelemed/platform";
import { DoneInvokeEvent, Interpreter, State } from "xstate";

import { UPDATE_EVENT } from "../constants";

export interface DoneLoginData {
  user: User;
  token: string;
}

export interface DoneOnboardData {
  user: User;
}

export interface FullClinicData {
  data: {
    currentClinic: string;
  };
}

export interface ApplicationContext {
  pings: number;
  user?: User;
  token?: string;
}

export interface ApplicationSchema {
  states: {
    loggedIn: {
      states: {
        maybeVerify: {};
        verifying: {};
        maybeOnboard: {};
        onboarding: {};
        checkingSession: {};
        maybeHydrateClinic: {};
        hydratingClinic: {};
        ready: {};
      };
    };
    notLoggedIn: {};
    loggingOut: {};
  };
}

// Events
export interface ChangeClinicEvent {
  type: "APP.CHANGE_CLINIC";
  clinic?: Clinic;
}

export interface HydatingClinicEventSuccess {
  type: "CL.SUCCEED";
}

export type EndSessionEvent = { type: "APP.END_SESSION" };

export type CheckSessionEvent = { type: "APP.CHECK_SESSION" };

export type FinishLogoutEvent = { type: "APP.FINISH_LOGOUT" };

export interface SuccessUserProviderResponse {
  user: User;
}

export interface FailEvent {
  type: "CL.FAIL";
  errors: string[];
}

export interface SucceedEvent {
  type: "CL.SUCCEED";
  clinic: Clinic;
}

export type ApplicationEvents =
  | ChangeClinicEvent
  | CheckSessionEvent
  | EndSessionEvent
  | FinishLogoutEvent
  | HydatingClinicEventSuccess
  | DoneInvokeEvent<any>
  | typeof UPDATE_EVENT;

export const isDoneEvent = <TData = {}>(
  event: ApplicationEvents,
): event is DoneInvokeEvent<TData> =>
  (event as DoneInvokeEvent<TData>).data !== undefined;

export type ApplicationState = State<
  ApplicationContext,
  ApplicationEvents,
  ApplicationSchema
>;

export type SendChangeClinic = (clinic: Clinic) => void;

export type SendLogout = () => void;

export interface ApplicationActions {
  changeClinic: SendChangeClinic;
  logout: SendLogout;
  refreshProvider: () => void;
  updateUser: (user: User) => void;
}

export interface ApplicationSelectors {
  isLoggedIn: boolean;
  needsOnboarding: boolean;
  needsVerifying: boolean;
  user?: User;
}

export type ApplicationServices = Record<string, Interpreter<any, any>>;

export type ApplicationHooks = [
  ApplicationSelectors,
  ApplicationServices,
  ApplicationActions,
];
