import { createContext } from "react";
import {
  CreateLocalTrackOptions,
  LocalAudioTrack,
  LocalVideoTrack,
  RemoteParticipant,
  Room,
  TwilioError,
} from "twilio-video";

export type ErrorCallback = (error: TwilioError) => void;

export interface Video {
  room: Room;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  connect: () => Promise<Map<string, RemoteParticipant> | void>;
  disconnect: () => void;
  onError: ErrorCallback;
  onDisconnect: (...args: any[]) => void;
  getLocalVideoTrack: (
    newOptions?: CreateLocalTrackOptions,
  ) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  isFullscreen: boolean;
  setFullscreen: (val: boolean) => void;
}

export const VideoContext = createContext<Video>(null!);
