import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import React, { FunctionComponent } from "react";

import { ActiveSessionService, useActiveSessionService } from "./state";

interface LogOffModalProps {
  activeSessionService: ActiveSessionService;
}

const TITLE = "You've been inactive for a while now.";

export const LogOffModalWarning: FunctionComponent<LogOffModalProps> = ({
  activeSessionService,
}) => {
  const [{ timeIsAlmostUp, timeRemaining }] =
    useActiveSessionService(activeSessionService);

  return (
    <Dialog
      open={timeIsAlmostUp}
      aria-labelledby="confirmation-modal-title"
      aria-describedby="confirmation-modal-description"
    >
      <DialogTitle id="confirmation-modal-title">{TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-modal-description">
          {`We will log you out in ${timeRemaining}`}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};
