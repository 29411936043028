import { makeStyles } from "@material-ui/core";
import React, { FunctionComponent, useRef, useEffect, useMemo } from "react";
import { Track, LocalVideoTrack, RemoteVideoTrack } from "twilio-video";

import { useMediaTrack, useVideoContext } from "../../hooks";

interface TrackProps {
  track: LocalVideoTrack | RemoteVideoTrack;
  isLocal?: boolean;
  priority?: Track.Priority | null;
}

const useVideoStyles = makeStyles({
  video: {
    width: "100%",
    objectFit: "contain",
  },
});

export const VideoTrack: FunctionComponent<TrackProps> = ({
  track,
  isLocal = false,
  priority,
}) => {
  const classes = useVideoStyles();
  const { isFullscreen } = useVideoContext();
  const ref = useRef<HTMLVideoElement>(null!);
  const mediaStreamTrack = useMediaTrack(track);
  const style = useMemo(
    () => (isFullscreen ? { maxHeight: "82vh" } : { maxHeight: "70vh" }),
    [isFullscreen],
  );

  useEffect(() => {
    const el = ref.current;

    el.muted = true;
    if (track.setPriority && priority) {
      track.setPriority(priority);
    }

    track.attach(el);
    return () => {
      track.detach(el);
      if (track.setPriority && priority) {
        // Passing `null` to setPriority will set the track's priority to that which it was published with.
        track.setPriority(null as unknown as Track.Priority);
      }
    };
  }, [track, priority]);

  // The local video track is mirrored if it is not facing the environment.
  const isFrontFacing =
    mediaStreamTrack?.getSettings().facingMode !== "environment";

  const videoStyles = {
    ...(isLocal && isFrontFacing ? { transform: "rotateY(180deg)" } : {}),
    ...style,
  };

  return (
    // eslint-disable-next-line jsx-a11y/media-has-caption
    <video ref={ref} className={classes.video} style={videoStyles} />
  );
};
