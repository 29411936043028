import { Machine, send, sendParent } from "xstate";

import { UPDATE_EVENT, VERIFICATION_MACHINE } from "../../../constants";

import { clearContext, saveVerification } from "./actions";
import {
  FAIL,
  FAILED,
  RESET,
  SEND_CODE,
  SKIP,
  SUCCEED,
  UNVERIFIED,
  VERIFY,
  VERIFYING,
  VERIFIED,
} from "./constants";
import { createSendCodePromise, createVerifyPromise } from "./promises";
import {
  VerificationEvents,
  VerificationContext,
  VerificationSchema,
} from "./types";

export const verifyMachine = Machine<
  VerificationContext,
  VerificationSchema,
  VerificationEvents
>(
  {
    id: VERIFICATION_MACHINE,
    initial: UNVERIFIED,
    context: {
      user: {
        email: "",
        firstName: "",
        lastName: "",
        id: "",
        phone: "",
        verifiedEmail: false,
        verifiedMobile: false,
        preferredLanguage: "en",
        use2Fa: false,
      },
    },
    entry: send(SEND_CODE),
    on: {
      [SEND_CODE]: {
        actions: createSendCodePromise,
      },
    },
    states: {
      [UNVERIFIED]: {
        on: {
          [SKIP]: {
            target: VERIFIED,
          },
          [VERIFY]: {
            target: VERIFYING,
            actions: "saveVerification",
          },
        },
      },
      [VERIFYING]: {
        invoke: {
          src: createVerifyPromise,
          id: "verify",
          onDone: {
            target: VERIFIED,
          },
          onError: {
            target: FAILED,
            actions: "clearContext",
          },
        },
      },
      [FAILED]: {
        entry: sendParent(UPDATE_EVENT, { delay: 200 }),
        exit: sendParent(UPDATE_EVENT, { delay: 200 }),
        on: {
          [RESET]: {
            target: UNVERIFIED,
          },
          [SKIP]: {
            target: VERIFIED,
          },
          [VERIFY]: {
            target: VERIFYING,
            actions: "saveVerification",
          },
        },
      },
      [VERIFIED]: {
        type: "final",
        data: ({ user }) => ({
          user: {
            ...user,
            verifiedMobile: true,
          },
        }),
      },
    },
  },
  {
    actions: {
      clearContext,
      saveVerification,
    },
  },
);
